import React from "react";
import CheckBox from "../CheckBox";

import styles from "./Table.module.css";

const columns = [
  {
    field: "surveyId",
    headerName: "SurveyId",
    width: 140,
  },
  {
    field: "sessionId",
    headerName: "SessionId",
    width: 200,
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 200,
  },
  {
    field: "business",
    headerName: "Business",
    width: 200,
  },
  {
    field: "responseDate",
    headerName: "ResponseDate",
    width: 200,
  },
  {
    field: "nps_1",
    headerName: "nps_1",
    width: 200,
  },
  {
    field: "comment_1",
    headerName: "comment_1",
    width: 200,
  },
  {
    field: "comment_2",
    headerName: "comment_2",
    width: 200,
  },
  {
    field: "npsOption",
    headerName: "npsOption",
    width: 200,
  },
];

function Table({ data }) {
  return (
    <div className={styles.tableContainer}>
      <table>
        <thead>
          <tr>
            <th style={{ color: "#FC8E56" }}>
              <CheckBox value={true} />
            </th>
            {columns.map((it) => (
              <th key={it.field}>{it.headerName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={`${row["surveyId"]} ${row["comment_1"]}`}>
              <td style={{ color: "#FC8E56" }}>
                <CheckBox value={true} />
              </td>
              {columns.map((col) => (
                <td key={col.field}>{row[col.field]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
