import React from "react";
import styles from "./Component.module.css";

import { ReactComponent as FailIcon } from "./failIcon.svg";
import { ReactComponent as SuccessIcon } from "./successIcon.svg";

import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import { OrangeButton } from "../../App";

import classnames from "classnames";

function Component({ title, message, type, onClose }) {
  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <div
          className={classnames(styles.title, {
            [styles.successTitle]: type !== "error",
            [styles.failTitle]: type === "error",
          })}
        >
          {type === "error" ? <FailIcon /> : <SuccessIcon />}
          {title}
        </div>
        <div className={styles.message}>{message}</div>
        <div className={styles.button}>
          <OrangeButton onClick={onClose} endIcon={<ArrowRightAltIcon />}>
            Entendido
          </OrangeButton>
        </div>
      </div>
    </div>
  );
}

export default Component;
