import React, { useState, useEffect, useRef } from "react";
import styles from "./App.module.css";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { Grid, Button, TextField } from "@material-ui/core";
import { FormikConsumer, useFormik } from "formik";
import * as Yup from "yup";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SearchIcon from "@material-ui/icons/Search";
import LaunchIcon from "@material-ui/icons/Launch";
import Table from "./components/Table/Table";
import Modal from "./components/Modal";
import axios from "axios";

import fileDownload from "js-file-download";
import { lightGreen, red } from "@material-ui/core/colors";
import { Search } from "@material-ui/icons";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF995B",
    },
  },
});

const successModal = {
  title: "Descarga exitosa de documentos",
  message: "La descarga de archivos se realizó con éxito.",
  type: "success",
};
const failModal = {
  title: "Lo sentimos, hubo un problema con la descarga",
  message:
    "La descarga de archivos no se realizó correctamente, vuelve a intentarlo o regresa más tarde.",
  type: "error",
};

export const OrangeButton = styled(Button)({
  border: 0,
  color: "white",
  fontStyle: "normal",
  fontWeight: 700,
  background: "#FF995B",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  textTransform: "Capitalize",
  "&:hover": {
    background: "#FF995B",
  },
  "&.Mui-disabled": {
    color: "white",
  },
});

export const TimePicker = styled(TextField)({
  fontStyle: "normal",
  textTransform: "Capitalize",
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
  },
});

function App() {
  const [data, setData] = useState([]);
  const [touched, setTouched] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [downloadName, setDownloadName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const xlsRef = useRef();

  const formik = useFormik({
    initialValues: {
      beginDate: "",
      beginTime: "",
      endDate: "",
      endTime: "",
    },
    validationSchema: Yup.object({
      beginDate: Yup.string().required("Required"),
      beginTime: Yup.string().required("Required"),
      endDate: Yup.string().required("Required"),
      endTime: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      const url = process.env.REACT_APP_API_URL;
      const beginDate = `${values.beginDate} ${values.beginTime}`;
      const endDate = `${values.endDate} ${values.endTime}`;

      //console.log('formik',{...values})

      axios
        .post(url, {
          fechaInicio: beginDate,
          fechaFin: endDate,
        })
        .then((res) => {
          if (res.data.lista) {
            const formatedList = res.data.lista?.map((it) => ({
              surveyId: it.id,
              sessionId: it.idSesion,
              phone: it.telefono,
              business: it.negocio,
              responseDate: it.fechaRespuesta,
              nps_1: it.nps,
              comment_1: it.comentario,
              comment_2: it.comentario2,
              npsOption: it.opcion,
            }));
            setData((prevState) => [...formatedList]);
            setDownloadUrl(res.data.resultado?.url);
            setDownloadName(res.data.resultado?.key?.replace("Excel/", ""));
          } else {
            setData([]);
            setDownloadUrl("");
            setDownloadName("");
          }
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setTouched(true);
        });
    },
  });

  const onExport = () => {
    //console.log({ downloadUrl, downloadName });
    //handleDownload(downloadUrl, downloadName)
    new Promise((res, rej) => {
      xlsRef.current.click();
      res();
    })
      .then(() => {
        setModalInfo(successModal);
        formik.resetForm({});
      })
      .catch(() => {
        setModalInfo(failModal);
      })
      .finally(() => {
        setIsModalOpen(true);
      });
  };

  /*   const handleDownload = (url, filename) => {
    return axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  }; */
  useEffect(() => {
    if (formik.values.beginDate && formik.values.endDate) {
      const bbDate = new Date(`${formik.values.beginDate}`);
      const eeDate = new Date(`${formik.values.endDate}`);
      if (bbDate.getTime() > eeDate.getTime()) {
        formik.resetForm({
          ...formik,
          values: { ...formik.values, endDate: "" },
        });
      } else {
        if (formik.values.beginTime && formik.values.endTime) {
          const bDate = new Date(
            `${formik.values.beginDate} ${formik.values.beginTime}`
          );
          const eDate = new Date(
            `${formik.values.endDate} ${formik.values.endTime}`
          );
          if (bDate.getTime() > eDate.getTime()) {
            formik.resetForm({
              ...formik,
              values: { ...formik.values, endTime: "" },
            });
          }
        }
      }
    }
  }, [formik.values, formik.values.beginDate, formik.values.beginTime]);

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>Descarga JSON</div>
          <div className={styles.buttons}>
            <OrangeButton
              variant="contained"
              startIcon={<SearchIcon />}
              disabled={
                !(formik.isValid && Object.keys(formik.touched).length > 0)
              }
              type="submit"
              onClick={(ev) => {
                formik.handleSubmit();
              }}
            >
              Buscar
            </OrangeButton>
            <OrangeButton
              variant="contained"
              startIcon={<LaunchIcon />}
              disabled={data.length === 0 || !touched}
              onClick={onExport}
            >
              Exportar
            </OrangeButton>
          </div>
        </div>
        <div className={styles.dateControls}>
          <Grid item>
            <TimePicker
              id="beginDate"
              label="Introduce fecha inicial"
              type="date"
              variant="outlined"
              inputProps={{ style: { textTransform: "uppercase" } }}
              InputLabelProps={{ shrink: true }}
              {...formik.getFieldProps("beginDate")}
            />
          </Grid>

          <Grid item>
            <TimePicker
              id="beginTime"
              label="Hora inicio"
              type="time"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              inputProps={{ step: 300 }}
              {...formik.getFieldProps("beginTime")}
            />
          </Grid>
          <Grid item>
            <TimePicker
              id="endDate"
              label="Introduce fecha final"
              type="date"
              variant="outlined"
              inputProps={{
                min: formik.values.beginDate,
                style: { textTransform: "uppercase" },
              }}
              InputLabelProps={{ shrink: true }}
              {...formik.getFieldProps("endDate")}
            />
          </Grid>
          <Grid item>
            <TimePicker
              id="endTime"
              label="Hora fin"
              type="time"
              variant="outlined"
              inputProps={{ step: 300, min: formik.values.beginTime + ":00" }}
              InputLabelProps={{ shrink: true }}
              {...formik.getFieldProps("endTime")}
            />
          </Grid>
        </div>
        {touched && (
          <div className={styles.tableContainer}>
            {data.length > 0 ? (
              <Table data={data} />
            ) : (
              <div className={styles.errorMessage}>
                <CancelIcon />
                No hay coincidencias con la búsqueda
              </div>
            )}
          </div>
        )}
        {isModalOpen && (
          <Modal {...modalInfo} onClose={() => setIsModalOpen(false)} />
        )}
      </div>
      <div className={styles.hidden}>
        <a ref={xlsRef} href={downloadUrl} target="_blank" download></a>
      </div>
    </ThemeProvider>
  );
}

export default App;
