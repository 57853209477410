import React from "react";
import styles from "./Component.module.css";

import { ReactComponent as Filled } from "./filled.svg";
import { ReactComponent as Empty } from "./empty.svg";

function Component({ id, value }) {
  return (
    <div className={styles.container}>
      <input
        className={styles.coreInput}
        type={"checkbox"}
        id={id}
        checked={value}
        readOnly
      ></input>
      {value ? <Filled /> : <Empty />}
    </div>
  );
}

export default Component;
